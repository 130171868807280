/**
 * 图片转base64
 * @param {obj} imgFile 图片文件对象
 * @return {obj} base64数据
 * obj.code     base转化后的代码
 * obj.ext      扩展名[jpg, png, gif] jpeg归类到jpg
 * obj.base64   canvas转化后的base64    
 */
export function img2base64(imgFile){
    return new Promise((resolve, reject) => {
        let imageUrl = URL.createObjectURL(imgFile), // 通过 file 生成目标 url
            image = new Image(),
            imgType = imgFile.type,
            canvas = document.createElement('canvas');
        image.src = imageUrl;
        image.onload = function (e) {
            // 获取图片角度，图片绘制
            getOrientation(imgFile, function(orientation){
                drawImage(image, orientation);
                let base64 = canvas.toDataURL(imgType);
                let code = base64.replace(/data:\s*image\/(\w+);base64,/gi, '');
                let ext = 'png';
                switch(imgType) {
                    case 'image/jpeg':
                    case 'image/jpg':
                        ext = 'jpg';
                        break;
                    case 'image/png':
                        ext = 'jpg';
                        break;
                    case 'image/gif':
                        ext = 'gif';
                        break;
                }
                resolve({
                    base64,
                    code,
                    ext
                })
            })
        }
        // 图片加载失败
        image.onerror = function (e) {
            console.warn('文件不是图片');
            reject();
        }
        // 绘制图片到canvas
        function drawImage(img, orientation) {
            let ctx = canvas.getContext('2d'),
                g = {};
            // 存储本地图片路径，当上传的图片不符合要求，用这张图片显示
            let maxWidth = 1500;
            let maxHeight = 20000;
            // 纠正旋转
            switch (orientation) {
                case 6:
                    // 需要顺时针 旋转90度
                    g = _getImageSize(img.height, img.width);
                    g.w = g.height;
                    g.h = g.width;
                    g.x = 0;
                    g.y = -g.width;
                    g.deg = 90;
                    break;
                case 8:
                    // 需要逆时针 旋转90度
                    g = _getImageSize(img.height, img.width);
                    g.w = g.height;
                    g.h = g.width;
                    g.x = -g.height;
                    g.y = 0;
                    g.deg = 270;
                    break;
                case 3:
                    // 需要顺时针 旋转180度
                    g = _getImageSize(img.width, img.height);
                    g.w = g.width;
                    g.h = g.height;
                    g.x = -g.width;
                    g.y = -g.height;
                    g.deg = 180;
                    break;
                default:
                    g = _getImageSize(img.width, img.height);
                    g.w = g.width;
                    g.h = g.height;
                    g.x = 0;
                    g.y = 0;
                    g.deg = 0;
            }

            canvas.width = g.width;
            canvas.height = g.height;

            !!g.deg && ctx.rotate(g.deg * Math.PI / 180);

            ctx.fillStyle = "#FFFFFF";
            ctx.fillRect(g.x, g.x, g.w, g.h);
            ctx.drawImage(img, g.x, g.y, g.w, g.h);

            function _getImageSize(width, height) {
                let g = {};
                let pr = window.devicePixelRatio || 2;

                if (width > maxWidth * pr) {
                    g.width = maxWidth * pr;
                    g.height = g.width * height / width;
                } else if (height > maxHeight * pr) {
                    g.height = maxHeight * pr;
                    g.width = g.height / (height / width);
                } else {
                    g.width = width;
                    g.height = height;
                }
                return g;
            }
        }
        //获取照片方向角属性https://github.com/blueimp/JavaScript-Load-Image
        function getOrientation(file, callback) {
            let TiffTags = {
                0x0112: "Orientation"
            };
            let orientation = 1;

            // 排除不是jpg的图片
            if (file.type !== 'image/jpeg') {
                callback(orientation);
                return false;
            }

            if (window.FileReader && (file instanceof window.Blob || file instanceof window.File)) {

                let fileReader = new FileReader();

                fileReader.onload = function (e) {

                    let exifdata = findEXIFinJPEG(e.target.result) || {},
                        orientation = exifdata.Orientation || 1;

                    callback(orientation);
                }
                fileReader.onerror = function (e) {
                    alert('您的浏览器版本过低，请更新您的系统');
                }
                fileReader.readAsArrayBuffer(file);
            }

            function findEXIFinJPEG(file) {
                let dataView = new DataView(file);

                if ((dataView.getUint8(0) != 0xFF) || (dataView.getUint8(1) != 0xD8)) {
                    return false; // not a valid jpeg
                }

                let offset = 2,
                    length = file.byteLength,
                    marker;

                while (offset < length) {
                    if (dataView.getUint8(offset) != 0xFF) {
                        return false; // not a valid marker, something is wrong
                    }

                    marker = dataView.getUint8(offset + 1);

                    // we could implement handling for other markers here,
                    // but we're only looking for 0xFFE1 for EXIF data

                    if (marker == 225) {

                        return readEXIFData(dataView, offset + 4, dataView.getUint16(offset + 2) - 2);

                        // offset += 2 + file.getShortAt(offset+2, true);

                    } else {
                        offset += 2 + dataView.getUint16(offset + 2);
                    }

                }
            }

            function readTags(file, tiffStart, dirStart, strings, bigEnd) {
                let entries = file.getUint16(dirStart, !bigEnd),
                    tags = {},
                    entryOffset, tag,
                    i;

                for (i = 0; i < entries; i++) {
                    entryOffset = dirStart + i * 12 + 2;
                    tag = strings[file.getUint16(entryOffset, !bigEnd)];
                    tags[tag] = readTagValue(file, entryOffset, tiffStart, dirStart, bigEnd);
                }
                return tags;
            }

            function readTagValue(file, entryOffset, tiffStart, dirStart, bigEnd) {
                let type = file.getUint16(entryOffset + 2, !bigEnd),
                    numValues = file.getUint32(entryOffset + 4, !bigEnd),
                    valueOffset = file.getUint32(entryOffset + 8, !bigEnd) + tiffStart,
                    offset,
                    vals, val, n,
                    numerator, denominator;

                switch (type) {
                    case 1: // byte, 8-bit unsigned int
                    case 7: // undefined, 8-bit byte, value depending on field
                        if (numValues == 1) {
                            return file.getUint8(entryOffset + 8, !bigEnd);
                        } else {
                            offset = numValues > 4 ? valueOffset : (entryOffset + 8);
                            vals = [];
                            for (n = 0; n < numValues; n++) {
                                vals[n] = file.getUint8(offset + n);
                            }
                            return vals;
                        }
                    case 2: // ascii, 8-bit byte
                        offset = numValues > 4 ? valueOffset : (entryOffset + 8);
                        return getStringFromDB(file, offset, numValues - 1);

                    case 3: // short, 16 bit int
                        if (numValues == 1) {
                            return file.getUint16(entryOffset + 8, !bigEnd);
                        } else {
                            offset = numValues > 2 ? valueOffset : (entryOffset + 8);
                            vals = [];
                            for (n = 0; n < numValues; n++) {
                                vals[n] = file.getUint16(offset + 2 * n, !bigEnd);
                            }
                            return vals;
                        }
                    case 4: // long, 32 bit int
                        if (numValues == 1) {
                            return file.getUint32(entryOffset + 8, !bigEnd);
                        } else {
                            vals = [];
                            for (n = 0; n < numValues; n++) {
                                vals[n] = file.getUint32(valueOffset + 4 * n, !bigEnd);
                            }
                            return vals;
                        }
                    case 5: // rational = two long values, first is numerator, second is denominator
                        if (numValues == 1) {
                            numerator = file.getUint32(valueOffset, !bigEnd);
                            denominator = file.getUint32(valueOffset + 4, !bigEnd);
                            val = new Number(numerator / denominator);
                            val.numerator = numerator;
                            val.denominator = denominator;
                            return val;
                        } else {
                            vals = [];
                            for (n = 0; n < numValues; n++) {
                                numerator = file.getUint32(valueOffset + 8 * n, !bigEnd);
                                denominator = file.getUint32(valueOffset + 4 + 8 * n, !bigEnd);
                                vals[n] = new Number(numerator / denominator);
                                vals[n].numerator = numerator;
                                vals[n].denominator = denominator;
                            }
                            return vals;
                        }
                    case 9: // slong, 32 bit signed int
                        if (numValues == 1) {
                            return file.getInt32(entryOffset + 8, !bigEnd);
                        } else {
                            vals = [];
                            for (n = 0; n < numValues; n++) {
                                vals[n] = file.getInt32(valueOffset + 4 * n, !bigEnd);
                            }
                            return vals;
                        }
                    case 10: // signed rational, two slongs, first is numerator, second is denominator
                        if (numValues == 1) {
                            return file.getInt32(valueOffset, !bigEnd) / file.getInt32(valueOffset + 4, !bigEnd);
                        } else {
                            vals = [];
                            for (n = 0; n < numValues; n++) {
                                vals[n] = file.getInt32(valueOffset + 8 * n, !bigEnd) / file.getInt32(valueOffset + 4 + 8 * n, !bigEnd);
                            }
                            return vals;
                        }
                }
            }

            function getStringFromDB(buffer, start, length) {
                let outstr = "";
                for (let n = start; n < start + length; n++) {
                    outstr += String.fromCharCode(buffer.getUint8(n));
                }
                return outstr;
            }

            function readEXIFData(file, start) {
                let bigEnd,
                    tags, tag,
                    exifData, gpsData,
                    tiffOffset = start + 6;

                // test for TIFF validity and endianness
                if (file.getUint16(tiffOffset) == 0x4949) {
                    bigEnd = false;
                } else if (file.getUint16(tiffOffset) == 0x4D4D) {
                    bigEnd = true;
                } else {
                    return false;
                }

                if (file.getUint16(tiffOffset + 2, !bigEnd) != 0x002A) {
                    return false;
                }

                let firstIFDOffset = file.getUint32(tiffOffset + 4, !bigEnd);

                if (firstIFDOffset < 0x00000008) {
                    return false;
                }
                tags = readTags(file, tiffOffset, tiffOffset + firstIFDOffset, TiffTags, bigEnd);

                return tags;
            }
        }
    })
}

/**
 * 视频截取图片
 * @param {*} obj 
 * obj.file     视频文件
 */
export function videoCut2base64(obj) {
    return new Promise((resolve, reject) => {
        const videoUrl = URL.createObjectURL(obj.file);
        const videoEl = document.createElement("video");
        videoEl.src = videoUrl;
        videoEl.style.cssText = "position:fixed; top:0; left:-100%; visibility:hidden";
        videoEl.onloadeddata = function() {
            let currentTime = 3; //截图时间点
            videoEl.addEventListener("timeupdate", function() {
                // const videoWidth = videoEl.videoWidth;
                // const videoHeight = videoEl.videoHeight;
                const canvas = document.createElement("canvas");
                canvas.width = 750;
                canvas.height = 422;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(videoEl, 0, 0, 750, 422);
                let base64 = canvas.toDataURL('image/jpg');
                let code = base64.replace(/data:\s*image\/(\w+);base64,/gi, '');
                resolve({
                    base64,
                    code,
                    ext: 'jpg'
                });
            });
            videoEl.currentTime = currentTime < 0 ? 1 : currentTime;
        };
        // edge浏览器必须要追加到dom中，才能顺利执行相关事件。
        document.body.appendChild(videoEl);
    })
}
/**
 * 获取视频信息
 * @param {*} obj 
 * obj.file     视频文件
 */
export function videoGetinfo(obj) {
    return new Promise((resolve, reject) => {
        const videoUrl = URL.createObjectURL(obj.file);
        const videoEl = document.createElement("video");
        videoEl.src = videoUrl;
        videoEl.style.cssText = "position:fixed; top:0; left:-100%; visibility:hidden";
        videoEl.onloadeddata = function() {
            videoEl.addEventListener("canplay", function() {
                const width = videoEl.videoWidth;
                const height = videoEl.videoHeight;
                const duration = parseInt(videoEl.duration);
                resolve({
                    width,
                    height,
                    duration
                });
            });
        };
        // edge浏览器必须要追加到dom中，才能顺利执行相关事件。
        document.body.appendChild(videoEl);
    })
}

/**
 * 获取音频数据
 * @param {*} obj 
 * obj.file     音频文件
 */
export function audioGetInfo(obj) {
    return new Promise((resolve, reject) => {
        const audioUrl = URL.createObjectURL(obj.file);
        let audioEl = document.createElement('audio'); 
        audioEl.src = audioUrl;
        audioEl.style.cssText = "position:fixed; top:0; left:-100%; visibility:hidden";
        audioEl.onloadeddata = function() {
            audioEl.addEventListener("canplay", function() {
                const duration = parseInt(audioEl.duration);
                resolve({
                    duration
                });
            });
        };
        // edge浏览器必须要追加到dom中，才能顺利执行相关事件。
        document.body.appendChild(audioEl);
    })
}
